import produce from 'immer';

import { EDIT_QUESTION } from 'redux/admin/editQuestion/actionConstants';

export const initialState = {
  question: {
    id: 0,
    description: '',
    marks: 0,
    timeInSeconds: 0,
    difficulty: '',
    tags: [],
    type: '',
    image_url: '',
  },
  isQuestionEditSuccess: false,
  isQuestionEditLoading: false,
  isQuestionEditError: false,
  QuestionEditErrorMessage: '',
  isQuestionLoading: true,
  editQuestionId: '',
  isSuccess: false,
};

const {
  EDIT_QUESTION_REQUEST_ACTION,
  EDIT_QUESTION_SUCCESS_ACTION,
  EDIT_QUESTION_FAILURE_ACTION,
  EDIT_QUESTION_CLEAR_STATE,
  SET_QUESTION_REQUEST_ACTION,
  SET_QUESTION_SUCCESS_ACTION,
  SET_QUESTION_FAILURE_ACTION,
} = EDIT_QUESTION;

const editQuestionReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        question: payload.question,
        isQuestionEditLoading: false,
        isQuestionEditSuccess: true,
        isSuccess: true,
      };
    case EDIT_QUESTION_FAILURE_ACTION:
      return {
        ...state,
        isQuestionEditLoading: false,
        isQuestionEditError: true,
        QuestionEditErrorMessage: payload.response.data.message,
        isSuccess: false,
      };
    case EDIT_QUESTION_REQUEST_ACTION:
      return {
        ...state,
        isQuestionEditSuccess: false,
        isQuestionEditLoading: true,
        isSuccess: false,
      };
    case EDIT_QUESTION_CLEAR_STATE:
      return { ...initialState };
    case SET_QUESTION_REQUEST_ACTION:
      return {
        ...state,
        isQuestionLoading: true,
      };
    case SET_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        question: payload.question,
        isQuestionLoading: false,
      };
    case SET_QUESTION_FAILURE_ACTION:
      return {
        ...state,
        isQuestionLoading: false,
      };
    default:
      return state;
  }
});

export default editQuestionReducer;
