import * as yup from 'yup';
import { intToAlphaCapital } from './utils';

export const schema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .required('Question Description is required field')
    .trim()
    .min(10, 'Question Description must be at least 10 characters')
    .max(350, 'Question Description must be at most 350 characters'),
  marks: yup.number().min(1).max(999).required(),
  timeInSeconds: yup
    .number()
    .min(1, 'Time must be greater than or equal to 1')
    .max(59940, 'Time must be less than or equal to 999')
    .required('Time is a required field'),
  difficulty: yup.object().nullable().required(),
  tags: yup.array().min(1).nullable().required(),
  type: yup.object().nullable().required(),

  mcqOptions: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          isAnswer: yup.bool().required(),
          description: yup.string().trim().min(1),
        })
        .test({
          name: 'description or optionDiagram',
          test(value) {
            if (value.description || value.optionDiagram) {
              return true;
            }
            return this.createError({
              path: `${this.path}.descriptionOrOptionsDiagram`,
              message: `Please add at least description or select a diagram for option ${intToAlphaCapital(
                parseInt(this.path.split('[')[1].split(']')[0], 10),
              )}`,
            });
          },
        }),
    )
    .required(),
});
