export const convertToMinute = (value, unit) => {
  const conversionRates = {
    second: 1 / 60,
    minute: 1,
    hour: 60,
  };

  const minutes = value * (conversionRates[unit] ?? 1);
  return Number.isInteger(minutes) ? minutes : +minutes.toFixed(2);
};

export const convertToSeconds = (value, unit) => {
  const conversionRates = {
    second: 1,
    minute: 60,
    hour: 3600,
  };

  const seconds = value * (conversionRates[unit] ?? 1);
  return Number.isInteger(seconds) ? seconds : +seconds.toFixed(2);
};
