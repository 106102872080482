export const tagsOptions = [
  { value: 'array', label: 'Array' },
  { value: 'string', label: 'String' },
  { value: 'greedy', label: 'Greedy' },
  { value: 'stack', label: 'Stack' },
  { value: 'queue', label: 'Queue' },
  { value: 'tree', label: 'Tree' },
  { value: 'binary search', label: 'Binary search' },
  { value: 'math', label: 'Math' },
  { value: 'graph', label: 'Graph' },
  { value: 'linked list', label: 'Linked list' },
  { value: 'hash function', label: 'Hash function' },
  { value: 'segment tree', label: 'Segment tree' },
  { value: 'sorting', label: 'Sorting' },
  { value: 'searching', label: 'Searching' },
  { value: 'binary tree', label: 'Binary tree' },
  { value: 'dynamic programming', label: 'Dynamic programming' },
];

export const difficultyOptions = [
  { value: 'easy', label: 'Easy' },
  { value: 'medium', label: 'Medium' },
  { value: 'hard', label: 'Hard' },
];

export const booleanOptions = [
  { value: 'false', label: 'Single Choice' },
  { value: 'true', label: 'Multiple Choice' },
];

export const unitOptions = [
  { value: 'second', label: 'Seconds' },
  { value: 'minute', label: 'Minutes' },
  { value: 'hour', label: 'Hours' },
];

export const yesNoOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];
