import produce from 'immer';

import { CREATE_QUESTION } from 'redux/admin/createQuestion/actionConstants';

export const initialState = {
  question: {
    id: 0,
    description: '',
    marks: 0,
    timeInSeconds: 0,
    difficulty: '',
    tags: [],
    type: '',
  },
  isQuestionCreateSuccess: false,
  isQuestionCreateLoading: false,
  isQuestionCreateError: false,
  QuestionCreateErrorMessage: '',
  isSuccess: false,
};

const {
  CREATE_QUESTION_SUCCESS_ACTION,
  CREATE_QUESTION_FAILURE_ACTION,
  CREATE_QUESTION_REQUEST_ACTION,
  CLEAR_STATE,
} = CREATE_QUESTION;

const createQuestionReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        question: payload.question,
        isQuestionCreateLoading: false,
        isQuestionCreateSuccess: true,
        isSuccess: true,
      };
    case CREATE_QUESTION_FAILURE_ACTION:
      return {
        ...state,
        isQuestionCreateLoading: false,
        isQuestionCreateError: true,
        QuestionCreateErrorMessage: payload.response.data.message,
        isSuccess: false,
      };
    case CREATE_QUESTION_REQUEST_ACTION:
      return {
        ...state,
        isQuestionCreateLoading: true,
        isSuccess: false,
      };
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
});

export default createQuestionReducer;
