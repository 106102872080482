/* eslint-disable no-else-return */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import CreateQuestionComponent from 'modules/admin/createQuestion/CreateQuestionComponent';

import { schema } from 'modules/admin/createQuestion/CreateQuestionContainer/schema';
import { createQuestionRequestAction, clearQuestionState } from 'redux/admin/createQuestion/action';
import { editQuestionRequestAction } from 'redux/admin/editQuestion/action';
import { tagsRequestAction } from 'redux/admin/tags/action';
import { setActiveTab } from 'redux/admin/questionTabs/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { MCQ_TAB } from 'constants/appConstants';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';

import { convertToSeconds } from 'utils/time';
import { correctOptionValidation, minTwoOptionValidation } from './utils';

// common for edit and create question containers
export const initialValues = {
  description: '',
  marks: 0,
  timeInSeconds: 0,
  difficulty: null,
  tags: null,
  type: null,
  questionDiagram: null,
  mcqOptions: [{ description: '', isAnswer: false, optionDiagram: null }],
  unit: { value: 'second', label: 'Seconds' },
};

const CreateQuestionContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { PROBLEMS } = ADMIN_ROUTES;
  const { ADMIN } = ROUTES;
  const {
    isQuestionCreateSuccess,
    isQuestionCreateLoading,
    question,
    isSuccess,
  } = useSelector((state) => state.createQuestionReducer);
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  const handleSubmit = useCallback((values) => {
    dispatch(createQuestionRequestAction(values));
  });

  const handleUpdateClick = useCallback((values) => {
    const nonDeletedOptions = values.mcqOptions.filter((option) => !option?.isDelete);
    if (nonDeletedOptions.length >= 5) {
      toast.error('Maximum 5 options are allowed');
    } else {
      // adding options ids coming from create question success in options stored in formik states
      values.mcqOptions.forEach((option, index) => {
        option.id = question.options[index].id;
      });
      dispatch(editQuestionRequestAction(values));
    }
  });

  const onSubmit = (values) => {
    if (!minTwoOptionValidation(values.mcqOptions)) {
      toast.error('Add at least two options');
    } else {
      const validationError = correctOptionValidation(values.type, values.mcqOptions);
      const updatedValues = {
        ...values,
        timeInSeconds: convertToSeconds(values.timeInSeconds, values.unit.value),
      };

      if (!validationError) {
        if (!isQuestionCreateSuccess) {
          handleSubmit(updatedValues);
        } else {
          handleUpdateClick(updatedValues);
        }
      } else {
        toast.error(validationError);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    // adding question id in values if question is created
    if (isQuestionCreateSuccess) {
      formik.values.id = question.id;
    }
  }, [isQuestionCreateSuccess]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setActiveTab(MCQ_TAB));
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
    }
  }, [isSuccess]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [mcqDescription, setMcqDescription] = useState();

  const handleOnOptionDelete = (index) => {
    if (formik.values.id) {
      if (formik.values.mcqOptions[index].id) {
        formik.setFieldValue(
          `mcqOptions[${index}].isDelete`,
          true,
        );
      } else {
        const { mcqOptions } = formik.values;
        mcqOptions.splice(index, 1);
      }
    } else {
      const { mcqOptions } = formik.values;
      mcqOptions.splice(index, 1);
    }
  };

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, description, remove) => {
    setTargetId(id);
    setMcqDescription(description);
    toggleConfirmation();
  };

  useEffect(() => {
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    return () => {
      dispatch(clearQuestionState());
    };
  }, []);

  const handleAddNextQuestionClick = (() => {
    // TO DO
    // on add next question click check if fields are chaged or not and show alert

    // if (question === formik.values) {
    //   //
    // }

    if (question.options.length >= 2) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.CREATE_QUESTION);
    } else toast.error('Add minimum 2 options');
  });

  const finishQuestionCreation = useCallback(() => {
    history.push(ADMIN + PROBLEMS);
  });

  const handleBackClick = (() => {
    // TO DO
    // on back click check if fields are chaged or not and show alert

    // if (!isQuestionCreateSuccess) {
    //   // questoin not created (submit button)
    //   if (formik.dirty) {
    //     // question is not created yet but fields are changed
    //     // alert for saving changed containt'
    //     // don't allow back click
    //   } else {
    //     // allow back click
    //   }
    // } else {
    //   // question is created (update button)
    //   // if () {
    //   //   // don't allow back click

    //   // } else {
    //   //   // allow back click
    //   // }
    // }

    // this condition controls back button click.
    // (1) before successfully submiting created mcq question back button should be accessible.
    // (2) it should not be accessible if mcq is created but you haven't added minimum of
    //     2 options
    // (3) it is accessible after user have added 2 options
    if (!isQuestionCreateSuccess || (isQuestionCreateSuccess && question.options.length >= 2)) {
      finishQuestionCreation();
    } else toast.error('Add minimum 2 options');
  });

  return (
    <CreateQuestionComponent
      isQuestionCreateSuccess={isQuestionCreateSuccess}
      isQuestionCreateLoading={isQuestionCreateLoading}
      finishQuestionCreation={finishQuestionCreation}
      handleUpdateClick={handleUpdateClick}
      questionId={question.id}
      pageTitle='Add New Question'
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      handleAddNextQuestionClick={handleAddNextQuestionClick}
      handleBackClick={handleBackClick}
      isCreateQuestion
      formik={formik}
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleOnOptionDelete={handleOnOptionDelete}
      toggleConfirmation={toggleConfirmation}
      handleDeleteClick={handleDeleteClick}
      targetId={targetId}
      mcqDescription={mcqDescription}
      confirmationModal={confirmationModal}
      toggleImageModalVisibility={toggleImageModalVisibility}
      showImageModal={showImageModal}
    />
  );
};

export default React.memo(CreateQuestionContainer);
